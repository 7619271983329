import http from '@/utils/http'
//
// 材料价格管理查询
export const getMaterialPage = p => http.post('/unibu/material/page', p)

// 材料价格管理删除
export const delMaterial = p => http.post('/unibu/material/del', p)

// 材料价格按条件批量删除
export const delMore2 = p => http.post('/unibu/material/delMore2', p)

// 材料价格管理新增编辑
export const modAddMaterial = p => http.post('/unibu/material/modAdd', p)

// 材料管理导入
export const importExcelMaterial = p => http.postForm('/unibu/material/importExcel', p)

// 材料管理导出
export const exportExcelMaterial = p => http.postFormExcel('/unibu/material/exportExcel', p)

// 类别数据字典
export const gainMaterialType = p => http.get('/unibu/dd/data_material_type', p)

// 材料管理导入模版下载
export const downloadTem = p => http.postFormExcel('/unibu/material/downloadTem', p)
