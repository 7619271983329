<template>
  <page-container>
    <div class="layout">
      <div style="width: 100%; display: flex; align-items: center">
        <el-form
          :inline="true"
          :model="searchModel"
          style="margin-top: 20px; padding-left: 20px"
          size="mini"
        >
          <el-form-item label="所属区域:">
            <el-cascader
              ref="myCascader"
              v-model="selectedOptions"
              clearable
              placeholder="请选择区域"
              :options="regionOptions"
              :props="regionProps"
              @change="handleItemChange"
              filterable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="日期:">
            <el-date-picker
              v-model="searchModel.dateShow"
              type="month"
              placeholder="选择日期"
              style="width: 180px"
              value-format="yyyy-MM">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="类别:">
            <el-cascader
              ref="dictCascader"
              v-model="dictSelectedOptions"
              clearable
              :options="dictOptions"
              :props="dictProps"
              @change="handleItemChange"
              filterable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="材料名称:" prop="materialName">
            <el-input v-model="searchModel.materialName"></el-input>
          </el-form-item>
          <el-form-item label="规格型号:" prop="model">
            <el-input v-model="searchModel.model"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="submitSearch"
              :loading="loading"
            >{{ searchText }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-close"
              v-if="loading"
              @click="loading = false"
            >停止</el-button
            >
          </el-form-item>
        </el-form>
      </div>

      <resize-wrap class="flex-1">
        <BaseTable
          id="priceMan"
          ref="xTable1"
          size="mini"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :align="allAlign"
          :seq-config="{
            startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
          }"
          :data="tableData"
        >
          <template #buttons>
            <el-button
              v-auth="'价格信息_价格管理_新增'"
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="
                dialogEdit = true;
                addRow();
              "
            >新增
            </el-button>
            <el-button
              v-auth="'价格信息_价格管理_导出'"
              type="default"
              icon="el-icon-upload2"
              @click="
                exportTabData();
              "
            >导出
            </el-button>
            <el-upload
              style="display:inline-block"
              action="#"
              class="upload"
              :auto-upload="false" accept=".xlsx,.xls" :on-change="handleExcel" :show-file-list="false">
              <el-button
                class="ml-10 mr-10"
                v-auth="'价格信息_价格管理_导入'"
                type="default"
                icon="el-icon-download"
              >导入
              </el-button></el-upload>
            <el-button
              v-auth="'价格信息_价格管理_批量删除'"
              type="danger"
              icon="el-icon-delete"
              @click="
                deleteMore();
              "
            >批量删除
            </el-button>
            <el-button
              v-auth="'价格信息_价格管理_模板下载'"
              type="success"
              icon="el-icon-tickets"
              @click="
                downloadTem();
              "
            >模板下载
            </el-button>
          </template>
          <vxe-table-column type="seq" title="序号" width="60" />
          <!--   <vxe-table-column field="projNum" title="编号" show-overflow="title" min-width="135" show-header-overflow="title"/>-->
          <vxe-table-column
            field="model"
            title="规格型号"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="year"
            title="年份"
            show-overflow="title"
            show-header-overflow="title"
            min-width="60"
          />
          <vxe-table-column
            field="month"
            title="月份"
            show-overflow="title"
            min-width="60"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="cityName"
            title="地区"
            show-overflow="title"
            min-width="120"
            show-header-overflow="title"
            :formatter="
              ({ celllValue, row, column }) => {
                if (row.cityName != '直辖区')
                  return row.provinName + row.cityName + row.countrName;
                else return row.provinName + row.countrName;
              }
            "
          />
          <vxe-table-column
            field="materialTypeName"
            title="类别"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="materialName"
            title="材料名称"
            show-overflow="title"
            min-width="60"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="unit"
            title="单位"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="price0"
            title="含税信息价"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="price1"
            title="除税信息价"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="price2"
            title="含税市场价"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <vxe-table-column title="操作" min-width="100">
            <template v-slot="{ row }">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                size="mini"
                @click="editRow(row)"
                v-auth="'价格信息_价格管理_修改'"
                title="修改"
              />
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="deleteRow(row)"
                v-auth="'价格信息_价格管理_删除'"
                title="删除"
              />
            </template>
          </vxe-table-column>
        </BaseTable>
      </resize-wrap>
      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="[
          'Sizes',
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Total',
        ]"
        @page-change="handlePageChange"
      />
      <el-dialog
        :title="dialogEditTitle"
        :visible.sync="dialogEdit"
        :v-if="dialogEdit"
        :append-to-body="true"
        width="750px"
        v-dialogDrag
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-form
          :inline="true"
          ref="priceForm"
          :model="priceEditModel"
          size="mini"
          label-position="right"
          :rules="rules"
          label-width="120px"
          style="margin-top: 20px"
        >
          <el-form-item label="日期:" prop="dateShow">
            <el-date-picker
              v-model="priceEditModel.dateShow"
              type="month"
              placeholder="选择日期"
              style="width: 200px"
              value-format="yyyy-MM">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="所属区域:">
            <el-cascader
              ref="editCascader"
              v-model="regionArr"
              clearable
              placeholder="请选择区域"
              :options="regionOptions"
              :props="regionProps"
              @change="handleItemChange"
              style="width: 200px"
              filterable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="类别:">
            <el-cascader
              ref="dictEditCascader"
              v-model="priceArr"
              clearable
              :options="dictOptions"
              :props="{ expandTrigger: 'hover' }"
              style="width: 200px"
              @change="handleItemChange"
              filterable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="材料名称:">
            <el-input v-model="priceEditModel.materialName" style="width: 200px">
            </el-input>
          </el-form-item>
          <el-form-item label="规格型号:">
            <el-input v-model="priceEditModel.model" style="width: 200px">
            </el-input>
          </el-form-item>
          <el-form-item label="单位:">
            <el-input v-model="priceEditModel.unit" style="width: 200px">
            </el-input>
          </el-form-item>
          <el-form-item label="含税信息价:">
            <el-input v-model="priceEditModel.price0" style="width: 200px">
            </el-input>
          </el-form-item>
          <el-form-item label="除税信息价:">
            <el-input v-model="priceEditModel.price1" style="width: 200px">
            </el-input>
          </el-form-item>
          <el-form-item label="含税市场价:">
            <el-input v-model="priceEditModel.price2" style="width: 200px">
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogEdit = false" size="mini">取消</el-button>
          <el-button type="primary" @click="onSubmitPriceEdit()" size="mini"
          >保存</el-button
          >
        </div>
      </el-dialog>
    </div>
  </page-container>
</template>

<script>
import moment from 'moment'
import { gainRegi } from '@/apis/common'
import { gainMaterialType, modAddMaterial, delMaterial, getMaterialPage, exportExcelMaterial, downloadTem, delMore2, importExcelMaterial } from '@/apis/priceManage/priceMan'
import XEUtils from 'xe-utils'
import { exportToxlsx } from '@/utils/tools'

export default {
  name: 'priceMan',
  data () {
    return {
      priceEditModel: {
        dateShow: '',
        materialPriceId: '',
        cityName: '',
        countrName: '',
        isLog: true,
        materialName: '',
        materialType: '',
        materialTypeName: '',
        model: '',
        opertime: '',
        price0: 0,
        price1: 0,
        price2: 0,
        provinName: '',
        regiCityId: '',
        regiCountrId: '',
        regiProvinId: '',
        status: 0,
        unit: '',
        year: '',
        month: ''
      },
      rules: {
        compName: [
          { required: true, message: '企业名称不能为空', trigger: 'blur' }
        ]
      },
      dialogEditTitle: '新增价格信息',
      dialogEdit: false,
      searchModel: {
        materialTypeName: '',
        model: '',
        month: '',
        year: '',
        dateShow: ''
      },
      searchText: '查询',
      loading: false,
      tableData: [],
      allAlign: 'center',
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: [
          'Sizes',
          'PrevJump',
          'PrevPage',
          'Number',
          'NextPage',
          'NextJump',
          'FullJump',
          'Total'
        ],
        perfect: true
      },
      lazyTrigger: false,
      regionOptions: [],
      selectedOptions: [],
      dictOptions: [],
      dictSelectedOptions: [],
      regionArr: [],
      priceArr: [],
      compDetailModel: {},
      regionProps: {
        // multiple: true,
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          const level = node.level
          if (level === 1) {
            resolve(node.children[0].label == '直辖区' ? [] : node.children)
            // resolve(node.children)
          }
          if (level === 2 && node.data.code) {
            if (node.children && node.children.lenght > 0) {
              resolve(node.children)
            } else {
              setTimeout(() => {
                gainRegi({
                  isMapJson: false,
                  regiLevel: node.level,
                  regiCodes: [node.data.code]
                }).then((res) => {
                  if (res.code == 200) {
                    const nodeArr = res.data[0].regiCountrs.map((val) => ({
                      value: val.regiCountrId,
                      label: val.countrName,
                      code: val.countrCode,
                      leaf: true
                    }))
                    resolve(nodeArr)
                  }
                })
              })
            }
          } else if (level == 3) {
            resolve([])
          }
        }
      },
      dictProps: {
        // multiple: true,
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          resolve([])
        }
      }
    }
  },
  methods: {
    handleExcel (file) {
      const formData = new FormData()
      formData.append('file', file.raw)
      formData.append('num', 1)
      importExcelMaterial(formData).then(res => {
        if (res.code == '200') {
          this.$message.success('文件导入成功！')
          this.tablePage.currentPage = 1
          this.refreshData()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleItemChange () {},
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    exportTabData () {
      exportExcelMaterial({
        ...this.searchModel,
        regiCountrId: this.selectedOptions[2]
          ? this.selectedOptions[2]
          : undefined,
        regiCityId: this.selectedOptions[1]
          ? this.selectedOptions[1]
          : undefined,
        regiProvinId: this.selectedOptions[0]
          ? this.selectedOptions[0]
          : undefined,
        materialType: this.dictSelectedOptions[this.dictSelectedOptions.length - 1]
      }).then(res => {
        exportToxlsx(res, '价格列表.xlsx')
      })
    },
    downloadTem () {
      downloadTem().then(res => {
        exportToxlsx(res, '模板.xlsx')
      })
    },
    refreshData () {
      const dateVal = this.searchModel.dateShow
      this.searchModel.year = dateVal.split('-')[0]
      this.searchModel.month = dateVal.split('-')[1]
      getMaterialPage({
        ...this.searchModel,
        regiCountrId: this.selectedOptions[2]
          ? this.selectedOptions[2]
          : undefined,
        regiCityId: this.selectedOptions[1]
          ? this.selectedOptions[1]
          : undefined,
        regiProvinId: this.selectedOptions[0]
          ? this.selectedOptions[0]
          : undefined,
        materialType: this.dictSelectedOptions[this.dictSelectedOptions.length - 1],
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize
      })
        .then((res) => {
          this.loading = false
          if (res.code == 200) {
            const data = res.data
            this.tableData = data.rows
            this.tablePage.total = data.totalSize
          } else {
            this.tableData = []
            this.tablePage.total = 0
          }
        })
        .catch((error) => {
          console.log(error)
          //  this.logining = false;
        })
    },
    addRow () {
      this.dialogEditTitle = '新增价格信息'
      this.priceEditModel = {
        dateShow: '',
        materialPriceId: '',
        cityName: '',
        countrName: '',
        isLog: true,
        materialName: '',
        materialType: '',
        materialTypeName: '',
        model: '',
        opertime: '',
        price0: 0,
        price1: 0,
        price2: 0,
        provinName: '',
        regiCityId: '',
        regiCountrId: '',
        regiProvinId: '',
        status: 1,
        unit: '',
        year: '',
        month: ''
      }
    },
    parentIds (arr1, id) {
      const temp = []
      const forFn = function (arr, id) {
        for (let i = 0; i < arr.length; i++) {
          const item = arr[i]
          if (item.dictValue == id) {
            forFn(arr1, item.dictParentValue)
            temp.push(item.dictValue)
            break
          } else {
            if (item.children) {
              forFn(item.children, id)
            }
          }
        }
      }
      forFn(arr1, id)
      return temp
    },
    editRow (row) {
      //  this.modifyDetailRow = row
      this.dialogEditTitle = '修改价格信息'
      const rowData = {}
      XEUtils.objectEach(this.priceEditModel, (item, key) => {
        if (key != 'compPId') {
          rowData[key] = row[key] ? row[key].toString() : ''
        } else {
          rowData[key] = row[key] ? row[key] : ''
        }
      })
      this.priceEditModel = Object.assign({}, rowData)
      this.regionArr = []
      if (row.regiCountrId) {
        gainRegi({
          isMapJson: false,
          regiLevel: 2,
          regiDto: {} /*  */
        }).then((res) => {
          const targetProvin = this.regionOptions.find(
            (item) => item.value == row.regiProvinId
          )
          let targetCity = res.data.find(
            (item) => item.regiCityId == row.regiCityId
          )
          targetCity = {
            value: targetCity.regiCityId,
            label: targetCity.cityName,
            code: targetCity.cityCode,
            children: targetCity.regiCountrs.map((item) => {
              return {
                value: item.regiCountrId,
                label: item.countrName,
                code: item.countrCode,
                leaf: true
              }
            })
          }
          targetProvin.children.forEach((item, index) => {
            if (item.value == row.regiCityId) {
              this.$set(targetProvin.children, index, targetCity)
            }
          })
        })
      }
      if (row.regiProvinId != '' || row.regiProvinId != '0') {
        this.regionArr.push(row.regiProvinId)
      }
      if (row.regiCityId != '' || row.regiCityId != '0') {
        this.regionArr.push(row.regiCityId)
      }
      if (row.regiCityId != '' || row.regiCityId != '0') {
        this.regionArr.push(row.regiCountrId)
      }
      this.priceEditModel.dateShow = row.month ? moment(row.year + '-' + row.month).format('YYYY-MM') : ''
      const parentArr = this.parentIds(this.dictOptions, this.priceEditModel.materialType)
      this.priceArr = parentArr
      this.dialogEdit = true
    },
    deleteMore () {
      this.$confirm('确定删除相关价格信息?', '删除价格', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        delMore2({
          ...this.searchModel,
          regiCountrId: this.selectedOptions[2]
            ? this.selectedOptions[2]
            : undefined,
          regiCityId: this.selectedOptions[1]
            ? this.selectedOptions[1]
            : undefined,
          regiProvinId: this.selectedOptions[0]
            ? this.selectedOptions[0]
            : undefined,
          materialType: this.dictSelectedOptions[this.dictSelectedOptions.length - 1]
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success('删除成功！')
            this.refreshData()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    deleteRow (row) {
      this.$confirm('确定删除该价格信息?', '删除价格', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        delMaterial({
          materialPriceId: row.materialPriceId
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success('删除成功！')
            this.refreshData()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    submitSearch () {
      this.loading = true
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    onSubmitPriceEdit () {
      const pathLabels =
        this.$refs.editCascader.getCheckedNodes()[0].pathLabels
      const pricePL = this.$refs.dictEditCascader.getCheckedNodes()[0].pathLabels
      this.$refs.priceForm.validate((valid) => {
        if (valid) {
          modAddMaterial({
            ...this.priceEditModel,
            regiCountrId: this.regionArr[2] ? this.regionArr[2] : undefined,
            regiCityId: this.regionArr[1] ? this.regionArr[1] : undefined,
            regiProvinId: this.regionArr[0] ? this.regionArr[0] : undefined,
            countrName: pathLabels[2] ? pathLabels[2] : undefined,
            cityName: pathLabels[1] ? pathLabels[1] : undefined,
            provinName: pathLabels[0] ? pathLabels[0] : undefined,
            materialTypeName: pricePL[pricePL.length - 1],
            materialType: this.priceArr[this.priceArr.length - 1],
            year: this.priceEditModel.dateShow.split('-')[0],
            month: this.priceEditModel.dateShow.split('-')[1]
          }).then((res) => {
            if (res.code === 200) {
              if (this.priceEditModel.compId != '') {
                this.$message.success('修改成功！')
              } else {
                this.$message.success('添加成功！')
              }
              this.dialogEdit = false
              this.refreshData()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    toTree (data) {
      const result = []
      if (!Array.isArray(data)) {
        return result
      }
      data.forEach(item => {
        delete item.children
      })
      const map = {}
      const newData = []
      data.forEach(item => {
        newData.push(item = {
          value: item.dictValue,
          label: item.dictName,
          leaf: true,
          dictParentValue: item.dictParentValue,
          dictValue: item.dictValue
        })
      })
      newData.forEach(item => {
        map[item.dictValue] = item
      })
      newData.forEach(item => {
        const parent = map[item.dictParentValue]
        if (parent) {
          (parent.children || (parent.children = [])).push(item)
          parent.leaf = false
        } else {
          result.push(item)
        }
      })
      return result
    }
  },
  mounted () {
    gainRegi({
      regiLevel: 1,
      isMapJson: false,
      regiDto: {}
    })
      .then((res) => {
        if (res.code == 200) {
          const regionOptions = res.data.map((val) => ({
            value: val.regiProvinId,
            label: val.provinName,
            code: val.provinCode,
            children: val.regiCitys.map((valCity) => ({
              value: valCity.regiCityId,
              label: valCity.cityName,
              code: valCity.cityCode,
              children: []
            }))
          }))
          this.regionOptions = regionOptions
        }
      })
      .catch((val) => {
        console.log(val)
      })
      // 材料类别
    gainMaterialType().then((res) => {
      if (res.code == 200) {
        const treeData = this.toTree(res.data)
        this.dictOptions = treeData
      }
    }).catch((val) => {
      console.log(val)
    })
    this.refreshData()
  }
}
</script>s

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background-color: white;
}
</style>
